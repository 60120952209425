
import { computed,defineComponent, inject, ref, nextTick } from "vue";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import UiUpload from "@/components/UI/UploadAvatar";
import UseUpload from "@/components/UI/UploadReview";
import UiInput from "@/components/UI/Input";
import UiSelect from "@/components/UI/Select";
import { useRouter } from "vue-router";
import qs from "qs";
export default defineComponent({
  name: "UserInfo",
  components: {
    UiInput,
    UiSelect,
    UiUpload,
    UseUpload
  },
    props: {
        title: {
            type: String,
            default: "",
        },

        content: {
            type: String,
            default: "",
        },
        file: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
  setup(props, ctx) {

    const my = ref<any>(null);
    let flag: any = null;
    const router = useRouter();
    const isDisable = ref(false)
    const files = ref([]);
      const nativeFile = computed(() => {
          return props.file;
      });
      function onChangeFile(e: any) {
          console.log(e);
          ctx.emit("update:file", e);
      }
      function handleInput(e: string) {
          ctx.emit("update:title", e);
      }

    axios
      .get("/M/User/Info", { params: { validate: true } })
      .then((res) => {
        if (res.data.success) {
          flag = res.data.obj.flag;
          res.data.obj.my.userSex = String(res.data.obj.my.userSex)
          my.value = res.data.obj.my;
          if (my.value.realName) {
            isDisable.value = true
          }

        } else {
          Toast({
            type: "error",
            title: res.data.msg,
          });
        }
      })
      .catch((err) => {
        console.log('err')
      });
    function back() {
      router.go(-1);
    }
      const imageReg = /image\/(.+)/;
      const videoReg = /video\/(.+)/;
      const loading = ref(false);
      const loadOption = {
          text: "正在上传...",
          color: "#df0024",
          textColor: "#df0024",
      };
      function upload(uploadFiles: any[]) {
          console.log("222222222222")
          return new Promise((resolve, reject) => {
              const fileEmpty = uploadFiles.length === 0;
              let url = "";

              let pics = "",
                  videos = "",
                  isImg = false,
                  isVideo = false;
              let file = null;
              const map4Reg = /\.mp4/g;
              console.log(uploadFiles,'99999999');
              uploadFiles.forEach((item) => {
                  console.log(uploadFiles,'88888888');
                  if (item.file) {
                      file = item.file;
                  } else {
                      pics = pics + (item.src ? item.src + "," : "");
                  }
              });

              if (fileEmpty || !file) {
                  resolve({ pics, videos });
              }

              if (file && imageReg.test((file as any).type)) {
                  console.log("333333333333333");
                  console.log(file,"777777777");

                  url = "/M/FroalaApi/UploadImage";
                  isImg = true;
              }
              if (file && videoReg.test((file as any).type)) {
                  url = "/M/FroalaApi/UploadVideo";
                  isVideo = true;
              }

              const formData = new FormData();
              uploadFiles.forEach((item) => {
                  formData.append("file", item.file);
              });

              try {
                  axios
                      .post(url, formData, {
                          timeout: 0,
                      })
                      .then((res) => {
                          if (res.data.success) {
                              if (isImg) {
                                  console.log("44444444444444")
                                  pics = pics + res.data.returnUrl;
                              }
                              if (isVideo) {
                                  videos = res.data.returnUrl;
                              }
                              resolve({ pics, videos });
                          }
                      });
              } catch (e) {
                  Toast({
                      type: "error",
                      title: Object.prototype.toString.call(e),
                  });
              }
          });
      }
    const userInfo = inject("userInfo") as any;
      const destoryKeepAlive = inject(
          "destoryKeepAlive"
      ) as () => Promise<unknown>;
    function saveInfo() {
      console.log(userInfo);
      const userid = userInfo.id;
      if (my.value.userImg) {
        const arr = my.value.userImg.split(";base64,");
        if (arr[1]) {
          my.value.userImg = arr[1];
        }else{
          my.value.userImg = "";
        }
      } else {
        my.value.userImg = "";
      }
        upload(files.value).then((res: any) => {
            console.log("111111111111")

            const data = qs.stringify({
                flag: flag,
                id: userid,
                userName: my.value.userName,
                sex: my.value.userSex,
                userUnit: my.value.userUnit,
                mobile: my.value.mobile,
                realName: my.value.realName,
                img64: my.value.userImg,
                userQq: my.value.userQq,
                nickName: my.value.nickName,
                personalSignature: my.value.personalSignature,
                userIntroduce: my.value.userIntroduce,
                ...res,
            });
            console.log("666666666",data)
            axios
                .post("/M/User/DoSaveMyInfo", data)
                .then((res) => {
                    if (res.data.success) {
                        Toast({
                            type: "success",
                            title: res.data.msg,
                            onClose: () => {
                                // back();
                                // location.reload();
                                destoryKeepAlive().then(() => {
                                    router.back();
                                });
                            },
                        });
                        nextTick(() => {
                            router.push({
                                name: "Mine"
                            })
                        });
                    } else {
                        Toast({
                            type: "error",
                            title: res.data.msg,
                        });
                    }
                })
                .catch((err) => {
                    console.log(err)
                });
        });

    }
      function handleContentInput(e: string) {
          ctx.emit("update:content", e);
      }
    return {
      my,
      saveInfo,
      back,
      loading,
      onChangeFile,
      nativeFile,
      handleContentInput,
      isDisable
    };
  },
});
