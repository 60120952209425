

import UploadReview from './src/UploadReview.vue'
import { App } from 'vue'


UploadReview.install = (app: App) => {
    app.component(UploadReview.name, UploadReview)
}

export default UploadReview
