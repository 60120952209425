<template>
  <div class="form-item">
    <label for="userImg">
      <div class="form-label" v-if="label">{{ label }}</div>
      <input
        type="file"
        name="file"
        class="upload"
        @change="onChange"
        accept="image/*"
      />
      <img class="avatar" :src="originSrc" alt="" />
    </label>
  </div>
  <teleport to="#picker">
    <div class="cropper" v-if="isShowCrop">
      <img :src="base64" ref="img" alt="" />
      <div class="operation">
        <button class="cancel" @click="onCancel">取消</button>
        <button class="confirm" @click="onCrop">确认</button>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import useUpload from "./useUpload";
export default defineComponent({
  name: "UploadAvatar",
  props: {
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
    name: {
      type: String,
      default: "",
    },
  },
  setup(props, ctx) {
    const { img, onCrop, base64, onChange, isShowCrop, onCancel , originSrc } = useUpload(
      props,
      ctx
    );

    return {
      img,
      onCrop,
      base64,
      isShowCrop,
      onChange,
      onCancel,
      originSrc
    };
  },
});
</script>


<style lang="scss" scoped>
.form-item {
  border-bottom: 1px solid #eee;
  padding-left: 73.5px;
  position: relative;
  height: 45px;
  line-height: 45px;
  text-align: right;
  color: #444444;
  font-size: 13.988px;
  padding-bottom: 1px;
}

.form-item input {
  width: 100%;
  text-align: right;
  outline: none;
  border: none;
  padding-right: 10px;
  box-sizing: border-box;
}

.form-label {
  width: 64px;
  position: absolute;
  left: 0;
  top: 0;
  height: 45px;
  line-height: 45px;
  text-align: left;
  padding-left: 10px;
}

.form-item-textarea {
  border-bottom: 1px solid #eee;
  padding-left: 66px;
  position: relative;
  text-align: right;
  padding-bottom: 10px;
}

.form-item-textarea label {
  height: 100%;
  display: inline-block;
  width: 100%;
}

.form-item-textarea textarea {
  width: 100%;
  text-align: right;
  outline: none;
  border: none;
  padding-right: 10px;
  box-sizing: border-box;
  font-family: Arial;
  margin-top: 10px;
}

.form-item .upload {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  opacity: 0;
}

.form-item .avatar {
  transform: translateX(-0.213rem);
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  vertical-align: middle;
}

.cropper {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10009;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba($color: #000000, $alpha: 0.75);

  img {
    max-width: 100%;
  }
}
.operation {
  position: absolute;
  width: 100%;
  bottom: 10px;
  height: 44px;
  line-height: 44px;
  display: flex;
  justify-content: space-around;
  z-index: 100000;
  button {
    outline: none;
    border: none;
    padding: 0 15px;
    width: 88px;
    background-color: #df0024;
    border: 1px solid #df0024;
    color: #fff;
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    height: 44px;
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 1.2;
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
    -webkit-appearance: none;
  }
  .cancel {
    background-color: #fff;
    border: 1px solid #fff;
    color: #666;
  }
}
</style>