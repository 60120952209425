<template>
  <main v-if="my">
    <ui-upload name="userImg" label="头像" v-model="my.userImg"></ui-upload>
    <div class="form">
      <div class="form-item mobile">
        <label for="mobile">
          <div class="form-label">手机</div>
          <span>
            <input type="text" id="mobile" name="mobile" v-model="my.mobile" />
            <router-link :to="{ name: 'Account' }" class="edit_change"
              >更改</router-link
            ></span
          >
        </label>
      </div>
      <ui-input label="用户名" name="username" v-model="my.userName"></ui-input>
      <ui-input label="昵称" name="nickname" v-model="my.nickName"></ui-input>
      <ui-input
        label="真实姓名"
        name="realname"
        v-model="my.realName"
        :disable="isDisable?true:false"
      ></ui-input>
      <ui-select
        label="性别"
        title="性别"
        name="sex"
        v-model="my.userSex"
        :options="[
          { value: '1', text: '男' },
          { value: '2', text: '女' },
        ]"
      ></ui-select>
      <!-- <div class="form-item">
                <label for="sex">
                    <div class="form-label">性别</div>
                    <select name="sex" id="sex" dir="rtl">
                        <option value="1" <#if my.userSex?? && my.userSex==1>selected="selected"</#if>>男</option>
                        <option value="2" <#if my.userSex?? && my.userSex==2>selected="selected"</#if>>女</option>
                    </select>

                </label>
            </div> -->
      <ui-input label="QQ号码" name="weixin" v-model="my.userQq"></ui-input>
      <ui-input
        label="单位名称"
        name="userunit"
        v-model="my.userUnit"
        :maxlength="50"
      ></ui-input>
      <ui-input
        label="个性签名"
        name="wishContent"
        v-model="my.personalSignature"
        :maxlength="50"
      ></ui-input>
      <!-- <ui-input
        label="个人介绍"
        name="userMore"
        v-model="my.userMore"
        type="textarea"
      ></ui-input> -->
      <div class="form-item-textarea">
          <label for="userMore">
              <div class="form-label">个人介绍</div>
              <textarea id="userMore" rows="4" v-model="my.userIntroduce" name="userMore"></textarea>
          </label>
      </div>
      <div class="form-item-textarea">
        <label for="userMore">
          <div class="form-label">上传资质</div>
              <div style="text-align: left;margin-top: 13px;margin-left: 20px"><span style="color:#df0024">(请上传您的营业执照)</span></div>
            <div class="upload" style="margin-left: 25px">
                <use-upload v-model="nativeFile" @filechange="onChangeFile"></use-upload>

            </div>

        </label>
      </div>

      <div class="save-area">
        <a href="javascript:;" @click="back">返回</a>
        <button class="save" @click="saveInfo">保存</button>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { computed,defineComponent, inject, ref, nextTick } from "vue";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import UiUpload from "@/components/UI/UploadAvatar";
import UseUpload from "@/components/UI/UploadReview";
import UiInput from "@/components/UI/Input";
import UiSelect from "@/components/UI/Select";
import { useRouter } from "vue-router";
import qs from "qs";
export default defineComponent({
  name: "UserInfo",
  components: {
    UiInput,
    UiSelect,
    UiUpload,
    UseUpload
  },
    props: {
        title: {
            type: String,
            default: "",
        },

        content: {
            type: String,
            default: "",
        },
        file: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
  setup(props, ctx) {

    const my = ref<any>(null);
    let flag: any = null;
    const router = useRouter();
    const isDisable = ref(false)
    const files = ref([]);
      const nativeFile = computed(() => {
          return props.file;
      });
      function onChangeFile(e: any) {
          console.log(e);
          ctx.emit("update:file", e);
      }
      function handleInput(e: string) {
          ctx.emit("update:title", e);
      }

    axios
      .get("/M/User/Info", { params: { validate: true } })
      .then((res) => {
        if (res.data.success) {
          flag = res.data.obj.flag;
          res.data.obj.my.userSex = String(res.data.obj.my.userSex)
          my.value = res.data.obj.my;
          if (my.value.realName) {
            isDisable.value = true
          }

        } else {
          Toast({
            type: "error",
            title: res.data.msg,
          });
        }
      })
      .catch((err) => {
        console.log('err')
      });
    function back() {
      router.go(-1);
    }
      const imageReg = /image\/(.+)/;
      const videoReg = /video\/(.+)/;
      const loading = ref(false);
      const loadOption = {
          text: "正在上传...",
          color: "#df0024",
          textColor: "#df0024",
      };
      function upload(uploadFiles: any[]) {
          console.log("222222222222")
          return new Promise((resolve, reject) => {
              const fileEmpty = uploadFiles.length === 0;
              let url = "";

              let pics = "",
                  videos = "",
                  isImg = false,
                  isVideo = false;
              let file = null;
              const map4Reg = /\.mp4/g;
              console.log(uploadFiles,'99999999');
              uploadFiles.forEach((item) => {
                  console.log(uploadFiles,'88888888');
                  if (item.file) {
                      file = item.file;
                  } else {
                      pics = pics + (item.src ? item.src + "," : "");
                  }
              });

              if (fileEmpty || !file) {
                  resolve({ pics, videos });
              }

              if (file && imageReg.test((file as any).type)) {
                  console.log("333333333333333");
                  console.log(file,"777777777");

                  url = "/M/FroalaApi/UploadImage";
                  isImg = true;
              }
              if (file && videoReg.test((file as any).type)) {
                  url = "/M/FroalaApi/UploadVideo";
                  isVideo = true;
              }

              const formData = new FormData();
              uploadFiles.forEach((item) => {
                  formData.append("file", item.file);
              });

              try {
                  axios
                      .post(url, formData, {
                          timeout: 0,
                      })
                      .then((res) => {
                          if (res.data.success) {
                              if (isImg) {
                                  console.log("44444444444444")
                                  pics = pics + res.data.returnUrl;
                              }
                              if (isVideo) {
                                  videos = res.data.returnUrl;
                              }
                              resolve({ pics, videos });
                          }
                      });
              } catch (e) {
                  Toast({
                      type: "error",
                      title: Object.prototype.toString.call(e),
                  });
              }
          });
      }
    const userInfo = inject("userInfo") as any;
      const destoryKeepAlive = inject(
          "destoryKeepAlive"
      ) as () => Promise<unknown>;
    function saveInfo() {
      console.log(userInfo);
      const userid = userInfo.id;
      if (my.value.userImg) {
        const arr = my.value.userImg.split(";base64,");
        if (arr[1]) {
          my.value.userImg = arr[1];
        }else{
          my.value.userImg = "";
        }
      } else {
        my.value.userImg = "";
      }
        upload(files.value).then((res: any) => {
            console.log("111111111111")

            const data = qs.stringify({
                flag: flag,
                id: userid,
                userName: my.value.userName,
                sex: my.value.userSex,
                userUnit: my.value.userUnit,
                mobile: my.value.mobile,
                realName: my.value.realName,
                img64: my.value.userImg,
                userQq: my.value.userQq,
                nickName: my.value.nickName,
                personalSignature: my.value.personalSignature,
                userIntroduce: my.value.userIntroduce,
                ...res,
            });
            console.log("666666666",data)
            axios
                .post("/M/User/DoSaveMyInfo", data)
                .then((res) => {
                    if (res.data.success) {
                        Toast({
                            type: "success",
                            title: res.data.msg,
                            onClose: () => {
                                // back();
                                // location.reload();
                                destoryKeepAlive().then(() => {
                                    router.back();
                                });
                            },
                        });
                        nextTick(() => {
                            router.push({
                                name: "Mine"
                            })
                        });
                    } else {
                        Toast({
                            type: "error",
                            title: res.data.msg,
                        });
                    }
                })
                .catch((err) => {
                    console.log(err)
                });
        });

    }
      function handleContentInput(e: string) {
          ctx.emit("update:content", e);
      }
    return {
      my,
      saveInfo,
      back,
      loading,
      onChangeFile,
      nativeFile,
      handleContentInput,
      isDisable
    };
  },
});
</script>

<style lang="scss" scoped>
main {
  padding: 0 10.013px;
  color: #444444;
  font-size: 13.988px;
}
.form-item {
  border-bottom: 1px solid #eee;
  padding-left: 73.5px;
  position: relative;
  height: 45px;
  line-height: 45px;
  text-align: right;
  padding-bottom: 1px;
}

.form-item input {
  width: 100%;
  text-align: right;
  outline: none;
  border: none;
  padding-right: 10px;
  box-sizing: border-box;
}

.form-item-textarea {
  border-bottom: 1px solid #eee;
  padding-left: 66px;
  position: relative;
  text-align: right;
  padding-bottom: 10px;
}

.form-item-textarea label {
  height: 100%;
  display: inline-block;
  width: 100%;
}

.form-item-textarea textarea {
  width: 100%;
  text-align: right;
  outline: none;
  border: none;
  padding-right: 10px;
  box-sizing: border-box;
  font-family: Arial;
  margin-top: 10px;
}

.mobile {
  padding-right: 25px;
  color: #444444;
  font-size: 14px;
}
.mobile span {
  position: absolute;
  right: 8px;
  height: 100%;
  font-size: 12px;
  color: #d64b4b;
  top: 0;
  display: flex;
  align-items: center;
}
.mobile span input {
  flex: 10;
  padding-right: 0;
}
.mobile span a {
  font-size: 12px;
  color: #d64b4b;
  width: 20px;
  display: block;
  flex: 2;
}

.form-label {
  width: 69px;
  position: absolute;
  left: 0;
  top: 0;
  height: 45px;
  line-height: 45px;
  text-align: left;
  padding-left: 10px;
}

/*.upload {*/
/*  !*position: absolute;*!*/
/*  z-index: 100;*/
/*  top: 0;*/
/*  right: 0;*/
/*}*/

.avatar {
  transform: translateX(-8px);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  vertical-align: middle;
}

select {
  appearance: none;
  -webkit-appearance: none;
  border: none;
  background: url(http://www.bncc.com/static/m/images/icon84.png) no-repeat
    right center;
  background-size: 4.5px 8.25px;
  padding-right: 11.25px;
  padding-left: 11.25px;
  outline: none;
  text-align: right;
  font-size: 14px;
  direction: rtl;
  width: 100%;
}

option {
  text-align: right;
  direction: ltr;
}

.save-area {
  width: 100%;
  height: 45px;
  position: relative;
  top: 50px;
}

.save-area a {
  margin-left: 5%;
  display: inline-block;
  width: 40%;
  height: 45px;
  background-color: #999;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
  border-radius: 3.75px;
  line-height: 45px;
  text-align: center;
  color: #ffffff !important;
  font-size: 15px;
}

.save-area button {
  margin-left: 10%;
  width: 40%;
  height: 45px;
  background-color: #d64b4b;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
  border-radius: 3.75px;
  line-height: 45px;
  text-align: center;
  color: #ffffff;
  border: none;
  outline: none;
  font-size: 15px;
}

.form-item-textarea {
    border-bottom: 1px solid #eee;
    padding-left: 1.76rem;
    position: relative;
    text-align: right;
    padding-bottom: 0.267rem;
}
.form-item-textarea textarea {
    width: 100%;
    text-align: right;
    outline: none;
    border: none;
    padding-right: 0.267rem;
    box-sizing: border-box;
    font-family: Arial;
    margin-top: 0.267rem;
}
</style>
